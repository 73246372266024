import API from './Api';

class RequisitionsService extends API {
    constructor(controller) {
        super(controller);
    }

    toBeConfirmed = (model) => {
        return this.API.post(`${this.controller}/pending-confirm`, model);
    };

    search = (model) => {
        return this.API.post(`${this.controller}/search`, model);
    };

    getByIdWithDetails = (id) => {
        return this.API.get(`${this.controller}/${id}/with-details`);
    };

    getDashboardInfo = () => {
        return this.API.get(`${this.controller}/get-dashboard-info`);
    };

    saveQuantities = (model) => {
        return this.API.post(`${this.controller}/update-quantities`, model);
    }

    confirm = (model) => {
        return this.API.post(`${this.controller}/confirm`, model);
    }

    changeStatus = (model) => {
        return this.API.post(`${this.controller}/change-status`, model);
    }

    searchItems = (model) => {
        return this.API.post(`${this.controller}/search/items`, model);
    }

    searchRequisitionDetails = (model) => {
        return this.API.post(`${this.controller}/details/totals`, model);
    }

    saveProductsQuantities = (model) => {
        return this.API.post(`${this.controller}/update-items-quantities`, model);
    }

    saveProductsQuantitiesByItem = (model) => {
        return this.API.post(`${this.controller}/update-items-quantities-by-item`, model);
    }

    downloadLayout = (model) => {
        return this.API.post(`${this.controller}/download-layout`, model);
    }

    downloadDetailedReport = (model) => {
        return this.API.post(`${this.controller}/download-detailed-report`, model);
    }

    downloadConcentratedRQReport = (model) => {
        return this.API.post(`${this.controller}/download-concentrated-rq-report`, model);
    }

    postDriverCheckList = (model) => {
        return this.API.post(`${this.controller}/check-list`, model);
    }

    getUnitsWithRequisition = (routeId, vehicleId, driverId, date) => {
        return this.API.get(`${this.controller}/search-units-with-rq?date=${date.toJSON()}&routeId=${routeId}&vehicleId=${vehicleId}&driverId=${driverId}`);
    }

    getUnitsWithRquisitionComplete = (routeId, vehicleId, driverId, date) => {
        return this.API.get(`${this.controller}/search-units-with-rq?year=${date.year()}&month=${date.month() + 1}&day=${date.date()}&routeId=${routeId}&vehicleId=${vehicleId}&driverId=${driverId}`);
    }

    searchTempDeliveries = (vehicleId, driverId, date) => {
        return this.API.get(`${this.controller}/search-temp-deliveries?year=${date.year()}&month=${date.month() + 1}&day=${date.date()}&vehicleId=${vehicleId}&driverId=${driverId}`);
    }

    getDailyRequisitionsByLunchRoom = (lunchRoomId, date) => {
        return this.API.get(`${this.controller}/get-daily-rqs-by-lunchroom?lunchRoomId=${lunchRoomId}&date=${date.toJSON()}`);
    }

    getPendingBillingRequisitions = (model) => {
        return this.API.get(`${this.controller}/pending-billing?date=${model.date.toJSON()}&lunchRoomId=${model.lunchRoomId}&merge=${model.merge}&folio=${model.folio}`);
    }
}

export default new RequisitionsService('requisitions');
