import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { withSnackbar } from 'notistack';
import classNames from "classnames";
import Modal from 'components/Modal';
import ViewRequisition from "views/ViewRequisition";
// UI Components
import {
    withStyles,
    Grid,
    CircularProgress,
    InputLabel,
    Button,
    Checkbox,
    FormControlLabel,
    TextField
} from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, Table, Select } from 'components';
// import { MTableToolbar } from 'material-table';
import { DatePicker } from "@material-ui/pickers";

//Services
import { WithTicket, WithSession, WithNotifications } from "hoc";
import requisitionsService from "services/requisitionsService";
import lunchRoomsService from "services/lunchRoomsService";

//Assets
import styles from './styles';

class Billing extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    state = {
        selectedItems: [],
        requisitions: [],
        date: Moment().toDate(),
        lunchRoomId: 0,
        merge: false,
        folio: "",
    };

    columns = [
        {
            title: "Folio",
            field: 'folio'
        },
        {
            title: "Unidad",
            field: 'lunchRoomName'
        },
        {
            title: "Estado",
            field: 'status',
            render: (row) => <span>{this.getStatus(row)}</span>
        },
        {
            title: "Es Extra",
            field: ' ',
            render: (row) => <span>{row.isExtra ? "Si" : ""}</span>
        },
        {
            title: "Fecha de entrega",
            field: 'deliveryDate',
            render: row => {
                return (
                    <span>{Moment.utc(row.deliveryDate).format("DD/MM/YYYY")}</span>
                );
            }
        },
    ];

    statuses = [
        { value: 0, label: "Todos" },
        { value: 1, label: "Por Recibir" },
        { value: 2, label: "Recibidos por revisar" },
        { value: 3, label: "Recibidos" },
        { value: 4, label: "Confirmados" },
        { value: 5, label: "Embarcados" },
        // { value: 6, label: "Cancelados" },
        { value: 7, label: "Entregados" },
        { value: 8, label: "Facturados" }
    ]

    componentDidMount = () => {
        this.getLunchRooms();
        this.search();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {
            date,
            lunchRoomId,
            merge,
        } = this.state;

        if (prevState.date != date || prevState.lunchRoomId != lunchRoomId || prevState.merge != merge) {
            this.search();
        }
    }

    changeStatus = async (requisition) => {
        const {
            statusId
        } = this.state;

        const response = await requisitionsService.changeStatus({
            supplierRequisitionsId: requisition.supplierRequisitionId,
            requisitionStatus: statusId == 1 ? 2 : 1,
            plasticBoxes: 0,
            updatePlasticBoxes: false
        }).catch(() => {
            this.props.addNotification({ message: "Error cambiando status", options: { variant: "error" } });
        });

        if (!response.ok || !response.data.data.result) {
            this.props.addNotification({ message: "Error actualizando requisición", options: { variant: "error" } });
            return false;
        }

        this.props.addNotification({ message: "Requisición actualizada correctamente", options: { variant: "success" } });
        this.search();
    }

    search = async () => {
        const {
            date,
            lunchRoomId,
            merge,
            folio,
        } = this.state;

        const model = {
            date,
            lunchRoomId,
            merge,
            folio,
        };

        const response = await requisitionsService.getPendingBillingRequisitions(model);
        if (response.ok && Array.isArray(response.data.data)) {
            this.setState({ requisitions: response.data.data });
        }
    }

    getStatus = (row) => {
        const status = this.statuses.find(x => x.value == row.statusId);
        return status ? status.label.replace(/s*$/, "") : "";
    }

    getLunchRooms = async () => {
        let response = await lunchRoomsService.getAll();

        if (response.ok && response.data.status != "Fail") {
            const lunchRooms = response.data.data.map(i => ({ value: i.lunchRoomId, label: i.lunchRoomName }));
            this.setState({ lunchRooms: [{ value: 0, label: "TODOS" }, ...lunchRooms] });
        }
    }

    getTitle = () => {
        return "Remisiones";
    }

    download = () => {
        const {
            date,
            lunchRoomId,
            merge,
        } = this.state;

        const model = {
            date,
            lunchRoomId,
            merge,
        };

        requisitionsService.downloadConcentratedRQReport(model);
    }

    renderFilters = () => {
        const {
            date,
            lunchRoomId,
            lunchRooms,
            merge,
            folio,
        } = this.state;

        const {
            classes
        } = this.props;

        return (
            <Grid container className={classes.root} justify="space-beetwen" style={{ padding: '15px' }}>
                <Grid item xs={12} md={3} lg={2} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Fecha</InputLabel>
                        <DatePicker
                            format="DD MMMM [del] YYYY"
                            value={date}
                            onChange={v => this.setState({ date: v })}
                            autoOk
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3} lg={2} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Unidad</InputLabel>
                        <Select
                            value={lunchRoomId}
                            onChange={(selected) => {
                                this.setState({ lunchRoomId: selected.value });
                            }}
                            options={lunchRooms}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3} lg={2} alignContent="center" className={classes.item}>
                    <FormControlLabel
                        control={(
                            <Checkbox
                                checked={merge}
                                color="primary"
                                name="policy"
                                label=""
                                onChange={(e) => this.setState({ merge: e.target.checked })}
                            />
                        )}
                        label="Concatenar pedidos por unidad"
                        style={{ marginTop: '10px' }}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={2} alignContent="center" className={classes.item}>
                    <InputLabel>Folio</InputLabel>
                    <TextField
                        type="text"
                        value={folio}
                        style={{ width: '100%' }}
                        onKeyPress={e => {
                            if (e.charCode === 13) {
                                this.search();
                            }
                        }}
                        onChange={(e) => {
                            if (e.target != null && e.target.value != null) {
                                this.setState({ folio: e.target.value });
                            }
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={3} lg={4} alignContent="center" className={classes.item}>
                    {
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.download}
                        >
                            Descargar
                        </Button>
                    }
                </Grid>
            </Grid>
        );
    }

    renderTable = () => {
        const {
            requisitions,
            selectedItems,
            copiyng,
        } = this.state;

        let items = requisitions;

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    {this.renderFilters()}
                    <Table
                        columns={this.columns}
                        items={copiyng ? selectedItems : items}
                        options={{
                            pageSize: copiyng ? 100 : 10,
                            toolbar: false,
                            search: false,
                            selection: true
                        }}
                        onSelectionChange={(selectedItems) => {
                            this.setState({ selectedItems });
                        }}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    renderViewModal = () => {
        const {
            requisitionIdToShow
        } = this.state;

        return (
            <Modal
                title="Detalle de requisición"
                isOpen={this.state.requisitionIdToShow != null}
                onRequestClose={() => {
                    this.setState({ requisitionIdToShow: null });
                }}
                style={{ width: '95%', height: '85%' }}
            >
                <ViewRequisition
                    id={requisitionIdToShow}
                    hideLayout
                    onClose={() => this.setState({ requisitionIdToShow: null })}
                />
            </Modal>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        const {
            loading,
        } = this.state;

        let isLoading = loading;
        return (
            <DashboardLayout title={this.getTitle()}>
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                {this.renderViewModal()}
                                {
                                    isLoading
                                        ? <CircularProgress />
                                        : this.renderTable()
                                }
                            </PortletContent>
                        </Portlet>
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default withRouter(withSnackbar(WithNotifications(WithSession(WithTicket(withStyles(styles)(Billing))))));